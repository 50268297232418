import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  setDate() {
    // select the column just after this one
    const td = this.element.parentElement.nextElementSibling;
    // Update the hidden field there with the select value
    td.querySelector('[id=sd_parution]').value = this.element.value;
  }
}