import { Controller } from "@hotwired/stimulus"
import { sendGetTurboStreamRequest } from "../../javascripts/request_helpers";
import { displayLoader } from '../../javascripts/loader';

export default class extends Controller {

  connect() {
    displayLoader(this.element.getAttribute('data-message') || "L'Inpi génère la synthèse de votre formalité. Merci de patienter...");

    this.checkSynthesisPresence(this.element);
    this.interval = setInterval(this.checkSynthesisPresence, 10000, this.element);
  }

  disconnect() {
    clearInterval(this.interval)
  }

  checkSynthesisPresence(element) {
    sendGetTurboStreamRequest(element.getAttribute('data-url'));
  }
}