import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "fileField", "newFileInfo", "newFileName", "newFileSize", "newFileTime", "actualFileBlock", "bottomBar", "newFileBottomBar", "loader" ]

  // Affiche les infos
  displayFileData() {
    // this.formTarget.requestSubmit();
    // console.log(this.fileFieldTarget.files[0]);
    this.newFileNameTarget.innerText = this.fileFieldTarget.files[0].name;
    this.newFileSizeTarget.innerText = this.humanFileSize(this.fileFieldTarget.files[0].size);
    this.actualFileBlockTarget.classList.add('hidden');
    this.newFileInfoTarget.classList.remove('hidden');
    this.bottomBarTarget.classList.add('hidden');
    this.newFileBottomBarTarget.classList.remove('hidden');
    if(this.fileFieldTarget.getAttribute('data-autosubmit') == 'true') {
      this.autoSubmit();
    }
  }

  autoSubmit() {
    let form = this.element.closest('form');
    // Affiche le loader pendant l'upload des fichiers
    form.addEventListener('turbo:submit-start', (event) => {
      this.loaderTarget.classList.remove('hidden');
    });

    form.addEventListener('turbo:submit-end', (event) => {
      this.loaderTarget.classList.add('hidden');
    });
    form.requestSubmit();
  }

  onDrop(event) {
    event.preventDefault();

    this.fileFieldTarget.files = event.dataTransfer.files;
    this.displayFileData();
  }

  dragOver(event) {
    event.preventDefault();
  }

  humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  deleteNewFile(event) {
    event.preventDefault();
    this.actualFileBlockTarget.classList.remove('hidden');
    this.newFileInfoTarget.classList.add('hidden');
    this.bottomBarTarget.classList.remove('hidden');
    this.newFileBottomBarTarget.classList.add('hidden');
    this.fileFieldTarget.value = null;
  }
}