import { Controller } from "@hotwired/stimulus"

  // Calculations for RBE
const rbeOperations = [
  // { 'DFRBEDF8': ['DFRBEDF9', 'DFRBEDF10'] },
  // { 'DFRBEDF12': ['DFRBEDF13', 'DFRBEDF14'] },
  // { 'DFRBEDF3': ['DFRBEDF92', 'DFRBEDF8', 'DFRBEDF12'] },
  // { 'DFRBEDF22': ['DFRBEDF23', 'DFRBEDF24', 'DFRBEDF25'] },
  // { 'DFRBEDF26': ['DFRBEDF27', 'DFRBEDF28', 'DFRBEDF29'] },
  // { 'DFRBEDF16': ['DFRBEDF19', 'DFRBEDF20', 'DFRBEDF21', 'DFRBEDF22', 'DFRBEDF26'] },
  { 'DFRBEDF39': ['DFRBEDF40', 'DFRBEDF41'] },
  { 'DFRBEDF42': ['DFRBEDF43', 'DFRBEDF44'] },
  { 'DFRBEDF92': ['DFRBEDF6', 'DFRBEDF7'] },
  { 'DFRBEDF93': ['DFRBEDF9', 'DFRBEDF10', 'DFRBEDF13', 'DFRBEDF14'] },
  { 'DFRBEDF94': ['DFRBEDF19', 'DFRBEDF20', 'DFRBEDF21'] },
  { 'DFRBEDF95': ['DFRBEDF23', 'DFRBEDF24', 'DFRBEDF25', 'DFRBEDF27', 'DFRBEDF28', 'DFRBEDF29'] }
]

export default class extends Controller {
  recalculatePercentages() {
    // Remplace le point par une virgule et tout ce qui n'est pas un chiffre ou une virgule par rien
    this.element.value = this.element.value.replace('.', ',').replace(/[^0-9|,]/g, '');
    this.calculatePercentageTotals();
  }

  // Recalculate totals for readonly fields
  calculatePercentageTotals() {
    // Find the klass of the element
    this.calculatePercentageForOne(Array.from(this.element.classList).find(klass => klass.startsWith('DFRBEDF')));
  }

  calculatePercentageForOne(klass) {
    // Find the calcul in operations constant
    const match = rbeOperations.filter(obj => Object.keys(obj).some(key => obj[key].includes(klass)))[0];
    // console.log(match);
    if(!match) return;

    const result = Object.keys(match)[0];
    const values = Object.values(match)[0];

    let total = 0.0;

    values.forEach(value => {
      // Transform comma to point for calculations
      let subTotal = parseFloat(document.getElementsByClassName(value)[0].value.replace(',', '.'));
      if(!subTotal) return;

      total += subTotal;
    });
    // If total == 0 then remove value completely, otherwise form validation fails
    if(total === 0) {
      document.getElementsByClassName(result)[0].value = null;
    } else {
      // Put back value in field with comma
      document.getElementsByClassName(result)[0].value = total.toFixed(2).toString().replace('.', ',');
    }

    // Try to update another(s) inputs if the result is found in other calculations
    this.calculatePercentageForOne(result);
  }
}