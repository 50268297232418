import { Controller } from "@hotwired/stimulus";
import { sendGetTurboStreamRequest } from "../../javascripts/request_helpers";

// The element should have following attributes:
// data-controller="http--call-on-click" -> To define the controller, must be on the element
// data-action="click->http--call-on-click#sendRequest" -> The event to trigger when clicking an element
// data-url : Url to fetch

export default class extends Controller {
  sendRequest(event) {
    event.preventDefault();
    sendGetTurboStreamRequest(this.element.getAttribute('data-url'));
  }
}