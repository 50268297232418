import { Modal } from 'flowbite';

let modal;

export function initModal() {
  let mainModalElement = document.getElementById('main-modal');
  if(!mainModalElement) return;

  modal = new Modal(mainModalElement);
}

export function returnModal() {
  return modal;
}