import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js';
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  connect () {
    useMutation(this, { attributes: true });
    this.initChoicesJs();
  }

  initChoicesJs() {
    // Si l'élément n'est pas désactivé et si une instance de Choices n'a pas été déjà créée
    if(!this.element.disabled && !this.element.classList.contains('choices__input')) {
      this.choicesSelect = new Choices(this.element, {
        searchResultLimit: 5,
        loadingText: "Chargement...",
        noResultsText: "Pas de résultats",
        itemSelectText: "",
        fuseOptions: {
          includeScore: true,
          thresold: 0.0,
          minMatchCharLength: 3
        },
      });
    }
  }

  mutate (entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
        if(this.element.disabled) {
          if(this.choicesSelect) {
            this.choicesSelect.disable();
            this.choicesSelect.destroy();
          }
        } else {
          this.initChoicesJs();
          this.choicesSelect.enable();
        }
      }
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-new-value') {
        this.choicesSelect.setChoiceByValue(this.element.getAttribute('data-new-value'));
        this.element.removeAttribute('data-new-value');
      }
    })
  }
}