import { Drawer } from 'flowbite';

let drawer;

export function initDrawer() {
  let drawerElement = document.getElementById('drawer-right');
  if(!drawerElement) return;

  const options = {
    placement: 'right',
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: '',
    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30'
  };
  drawer = new Drawer(drawerElement, options);
}

export function returnDrawer() {
  return drawer;
}