import { Controller } from '@hotwired/stimulus'

// Set la valeur du champ caché 'form_action' à continue ou validate

export default class extends Controller {
  connect() {
    if (this.element.id === 'check_data_submit') {
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('after_load') == 'check_data') {
        this.element.click();
      }
    }
  }

  saveAndContinue(event) {
    document.getElementById('form_action').value = 'continue';
  }

  checkData() {
    document.getElementById('form_action').value = 'validate';
  }
}