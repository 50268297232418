// import { sendTurboStreamRequest, sendJSONRequest } from "./request_helpers";
const axios = require('axios');

// Return only the path from a name like rubric_names[ICM/M01/M01.1]
export function returnPathFromName(name) {
  return name.substring(
    name.lastIndexOf("[") + 1,
    name.lastIndexOf("]")
  );
}

// export function updateFormRubric(newValue, name, updateType) {
//   // sendTurboStreamRequest(document.getElementById('update_form_rubric_url').value, 'PATCH',
//   // {
//   //   field_ids: [...document.querySelectorAll('.form-field')].map(item => item.id).join(','),
//   //   new_value: newValue, rubric_path: name,
//   //   element_ids: [...document.querySelectorAll('.form-element')].map(item => item.id).join(','),
//   //   multiple_ids: [...document.querySelectorAll('.form-multiple')].map(item => item.id).join(','),
//   //   update_type: updateType
//   // });
//   sendJSONRequest(`${document.getElementById('update_form_rubric_url').value}.json`, 'PATCH', {
//     field_ids: [...document.querySelectorAll('.form-field')].map(item => item.id).join(','),
//     new_value: newValue, rubric_path: name,
//     element_ids: [...document.querySelectorAll('.form-element')].map(item => item.id).join(','),
//     multiple_ids: [...document.querySelectorAll('.form-multiple')].map(item => item.id).join(','),
//     update_type: updateType
//   }).then(function (response) {
//     const data = response.json();
//       // const data = response.data;
//       // Put reloaded delements first to refresh as fast as possible
//       parseRubricsDisplay(data);
//       parseElementsDisplay(data);
//       parseMultiplesDisplay(data);
//       rebuildSidebar(data);
//       rebuildLegalNotice(data);
//       parseReloadedElements(data);
//       loadChoicesJS();
//     })
//     .catch(function (error) {
//       // handle error
//       console.log(error);
//     })
// }

export function updateOnFormFieldChange(element, newValue) {
  // var name = returnPathFromName(this.element.name);
  updateFormRubric(newValue, returnPathFromName(element.name), 'complete');
}

export function updateFormRubric(newValue, name, updateType) {
  axios({
    url: `${document.getElementById('update_form_rubric_url').value}.json`,
    method: 'patch',
    headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0]?.content },
    data: {
      field_ids: [...document.querySelectorAll('.form-field')].map(item => item.id),
      new_value: newValue, rubric_path: name,
      element_ids: [...document.querySelectorAll('.form-element')].map(item => item.id),
      multiple_ids: [...document.querySelectorAll('.form-multiple')].map(item => item.id),
      update_type: updateType
    } })
    .then(function (response) {
      const data = response.data;
      // console.log(response.data);
      // Put reloaded delements first to refresh as fast as possible
      parseRubricsDisplay(data);
      parseElementsDisplay(data);
      parseMultiplesDisplay(data);
      rebuildSidebar(data);
      rebuildLegalNotice(data);
      parseReloadedElements(data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
}

function parseRubricsDisplay(data) {
  for(const rubric_display of data.rubrics_display) {
    const el = document.getElementById(rubric_display.path);

    if(rubric_display.displayed == false) {
      el.classList.add('hidden');
      el.querySelectorAll('.asterisque').forEach(element => element.textContent = '');
      el.querySelectorAll('input, select, textarea').forEach(element => {
        if(!element.classList.contains('percentage-field')) element.setAttribute('disabled', 'true');
      });
    } else {
      el.classList.remove('hidden');
      if(rubric_display.mandatory == true) {
        el.querySelectorAll('.asterisque').forEach(element => element.textContent = '*');
      } else {
        el.querySelectorAll('.asterisque').forEach(element => element.textContent = '');
      }
      el.querySelectorAll('input, select, textarea').forEach(element => element.removeAttribute('disabled'));
    }
  }
}

function parseElementsDisplay(data) {
  for(const element_display of data.elements_display) {
    if(element_display.display === 0) {
      document.getElementById(element_display.path).classList.add('hidden');
    } else {
      document.getElementById(element_display.path).classList.remove('hidden');
    }
  }
}

function parseMultiplesDisplay(data) {
  for(const multiple_display of data.multiples_display) {
    if(multiple_display.display === 0) {
      document.getElementById(multiple_display.path).classList.add('hidden');
    } else {
      document.getElementById(multiple_display.path).innerHTML = multiple_display.html;
    }
  }
}

function rebuildSidebar(data) {
  if(data.sidebar) {
    document.getElementById('sidenav').outerHTML = data.sidebar.html;
    document.getElementById('next_form_step').value = data.sidebar.next_form_step;
  }
}

function rebuildLegalNotice(data) {
  if(data.legal_notice) {
    // replaceElement(document.getElementById('legal-notice-validation-block'), data.legal_notice.validation_block);
    replaceElement(document.getElementById('legal-notice-content-buttons'), data.legal_notice.content_buttons);
  }
}

export function replaceElement(element, html) {
  element.insertAdjacentHTML('beforebegin', html);
  element.remove();

  // This does not work properly with tables
  // console.log(document.createRange().createContextualFragment(html))
  // element.parentNode.replaceChild(document.createRange().createContextualFragment(html), element);
}

function parseReloadedElements(data) {
  let reload = false;
  for(const element of data.reloaded_elements) {
    // console.log(element);
    document.getElementById(element.path).innerHTML = element.html;
    if(element.path.includes('DF.79') || element.path.includes('DF.80') || element.path.includes('DF.81')) {
      reload = true;
    }
  }
  // if(reload == true) {
  //   reloadChoicesJS();
  // }
}

