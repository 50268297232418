import { Controller } from "@hotwired/stimulus"
import { sendGetTurboStreamRequest } from '../../javascripts/request_helpers';
import { returnPathFromName } from "../../javascripts/form_content_helpers";

export default class extends Controller {
  autocomplete() {
    const siren = this.element.value.replace(/\s/g, '');
    // console.log(siren);
    if(siren.length != 9 || siren === this.element.getAttribute('data-last-value')) {
      return false;
    } else {
      this.element.setAttribute('data-last-value', siren);

      sendGetTurboStreamRequest('/search/siren/' + this.element.getAttribute('data-form-content-id') + '/' + siren + '?path=' + returnPathFromName(this.element.getAttribute("name")));
    }
  }
}