import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  search() {
    // this.setFormUrl();
    this.formTarget.requestSubmit();
  }

  searchWithTimeout() {
    // this.setFormUrl();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 300)
  }

  // setFormUrl() {
  //   let url = new URL(window.location);
  //   url.searchParams.delete('page');
  //   this.formTarget.setAttribute('action', url);
  // }
}