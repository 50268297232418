import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "warning" ]

  showWarning() {
    this.warningTarget.classList.remove('hidden');
  }

  hideWarning() {
    this.warningTarget.classList.add('hidden');
  }
}