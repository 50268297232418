import { Controller } from "@hotwired/stimulus"
import { sendTurboStreamRequest } from "../../javascripts/request_helpers";

// The element should have following attributes:
// data-controller="inpi--check-credentials" -> To define the controller, must be on the element
// data-action="click->inpi--check-credentials#sendRequest"

export default class extends Controller {
  // This permits to load the siren search on page load if the siren field is filled
  sendRequest(event) {
    event.preventDefault();
    sendTurboStreamRequest(this.element.href, 'POST', {
      username: document.getElementById('user_inpi_username').value,
      password: document.getElementById('user_inpi_password').value
    })
  }
}