
export function sendGetTurboStreamRequest(url) {
  return fetch(url, {
    headers: {
      Accept: "text/vnd.turbo-stream.html",
    }
  }).then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
}

// .then((function(response) {
//   if (!response.ok) {
//       throw Error(response.statusText);
//   }
//   return response;
// }))

// method is http method (POST, PATCH, DELETE etc)
export function sendTurboStreamRequest(url, method, data) {
  const formData = new FormData();
  for ( var key in data ) {
    formData.append(key, data[key]);
  }
  return fetch(url, {
    method: method,
    headers: {
      Accept: "text/vnd.turbo-stream.html",
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0]?.content
    },
    body: formData
  }).then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
}

export function sendJSONRequest(url, method, data) {
  const formData = new FormData();
  for ( var key in data ) {
    formData.append(key, data[key]);
  }
  return fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0]?.content
    },
    body: formData
  });
}