import { Controller } from "@hotwired/stimulus"
import { needRecording, recordAction } from '../../javascripts/recording';
import { returnPathFromName, updateOnFormFieldChange } from "../../javascripts/form_content_helpers";
import { sendGetTurboStreamRequest } from "../../javascripts/request_helpers";

export default class extends Controller {
  updateContent() {
    let newValue = '';

    // if(this.element.value) {
    //   this.element.classList.remove('is-invalid');
    // }

    if(!this.element.classList.contains('no-update')) {
      // Si checkbox récupération des valeurs différente
      if(this.element.matches('[type="checkbox"]')) {
        if(this.element.checked) {
          newValue = this.element.value;
        } else {
          // Get previous hidden field value if unchecked
          newValue = this.element.previousElementSibling.value;
        }

        // if(needRecording() === true) {
        //   recordAction('check', returnPathFromName(element.name), '');
        // }
      } else {
        newValue = this.element.value;
      }
      updateOnFormFieldChange(this.element, newValue);
    }

    if(this.element.classList.contains('country-select')) {
      this.replaceCityField();
    }

    // if(this.element.classList.contains('perm-rep-status-select')) {
    //   this.fillPermanentRepresentativeInfos();
    // }

    // Select
    // if(needRecording() === true) {
    //   recordAction('select', returnPathFromName(this.element.name) || this.element.name, this.element.options[this.element.selectedIndex].innerHTML);
    // }

    // Radio
    // if(needRecording() === true) {
    //   recordAction('choose', returnPathFromName(element.name), element.nextElementSibling.textContent);
    // }
  }

  replaceCityField() {
    sendGetTurboStreamRequest('/forms/replace_city_field/' + this.element.getAttribute('data-form-content-id') + '?path=' + returnPathFromName(this.element.getAttribute("name")) + '&country=' + this.element.value);
  }

  // fillPermanentRepresentativeInfos() {
  //   sendGetTurboStreamRequest(document.getElementById('fill_rep_url').value + '?path=' + returnPathFromName(this.element.getAttribute("name")) + '&rubric_value=' + this.element.value);
  // }
}