import { Controller } from "@hotwired/stimulus"
import { sendGetTurboStreamRequest } from '../../javascripts/request_helpers';
import { returnPathFromName } from "../../javascripts/form_content_helpers";

export default class extends Controller {
  autocomplete() {
    const zipCode = this.element.value.replace(/\s/g, '');
    // console.log(siren);
    if(zipCode.length != 5 || zipCode === this.element.getAttribute('data-last-value')) {
      return false;
    } else {
      this.element.setAttribute('data-last-value', zipCode);

      sendGetTurboStreamRequest('/search/zip_code/' + this.element.getAttribute('data-form-content-id') + '/' + zipCode + '?path=' + returnPathFromName(this.element.getAttribute("name")));
    }
  }
}