import { StreamActions } from "@hotwired/turbo"
import { hideLoader } from "./loader";

// Custom Turbo stream actions

StreamActions.add_css_class = function() {
  this.targetElements.forEach(element => element.classList.add(this.getAttribute("class_name")));
}

// StreamActions.remove_css_class = function() {
//   const className = this.getAttribute("class_name")
//   this.targetElements.forEach(element => element.classList.remove(className))
// }

StreamActions.replace_css_class = function() {
  this.targetElements.forEach(element => {
    element.classList.remove(this.getAttribute("old_class_name"));
    element.classList.add(this.getAttribute("new_class_name"));
  });
}

StreamActions.set_value = function() {
  this.targetElements.forEach(element => setRightValue(element, this.getAttribute("new_value")));
}

StreamActions.set_href = function() {
  this.targetElements.forEach(element => element.href = this.getAttribute("new_href"));
}

StreamActions.scroll_into_view = function() {
  this.targetElements.forEach(element => element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }));
}

StreamActions.hide_loader = function() {
  hideLoader();
}

StreamActions.redirect = function() {
  window.location.href = this.getAttribute('url');
}

// Click element
StreamActions.click_element = function() {
  // console.log(this.targetElements);
  this.targetElements.forEach(element => element.click());
}

// function setValueIfEmpty(response) {
//   const selection = returnElementByTarget(response);
//   if(selection.value) return;

//   setValue(response);
// }

function setRightValue(element, value) {
  if(element.classList.contains("choices__input")) {
    element.setAttribute('data-new-value', value);
  } else {
    element.value = value;
  }
}
