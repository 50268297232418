import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "username", "password", "button" ]

  connect() {
    this.enableButton();
  }

  enableButton() {
    if(this.usernameTarget.value && this.passwordTarget.value) {
      this.buttonTarget.classList.remove('pointer-events-none');
      this.buttonTarget.classList.remove('opacity-50');
    } else {
      this.buttonTarget.classList.add('pointer-events-none');
      this.buttonTarget.classList.add('opacity-50');
    }
  }
}