import { Controller } from "@hotwired/stimulus";
import { sendGetTurboStreamRequest } from "../../javascripts/request_helpers";

// The element should have following attributes:
// data-controller="http--call-on-input" -> To define the controller, must be around the element to grab the secondary message
// data-action="input->http--call-on-input#sendRequest" -> The event to trigger the search on text input
// data-url : Url to fetch, should be absolute url
// data-message : The message to display under the field during the request

export default class extends Controller {
  static targets = [ "message", "field" ]

  // This permits to load the siren search on page load if the siren field is filled
  connect() {
    this.sendRequest();
  }

  sendRequest() {
    if(!this.fieldTarget.value || this.fieldTarget.value.length == 0) return;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = new URL(this.fieldTarget.getAttribute('data-url'));
      // Retrieve existing query string
      let search_params = url.searchParams;
      // Add query to potential existing query strings
      search_params.set('query', this.fieldTarget.value);
      // Change the search property of the url
      url.search = search_params.toString();

      this.messageTarget.innerHTML = this.fieldTarget.getAttribute('data-message');

      sendGetTurboStreamRequest(url.toString())
        .then(r => this.messageTarget.innerHTML = null);
    }, 300)
  }
}