import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "fileField", "form", "loader" ]

  connect () {
    // Affiche le loader pendant l'upload des fichiers
    this.formTarget.addEventListener('turbo:submit-start', (event) => {
      this.loaderTarget.classList.remove('hidden');
    });

    this.formTarget.addEventListener('turbo:submit-end', (event) => {
      this.loaderTarget.classList.add('hidden');
    });
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  onDrop(event) {
    event.preventDefault();

    this.fileFieldTarget.files = event.dataTransfer.files;
    this.submitForm();
  }

  dragOver(event) {
    event.preventDefault();
  }
}