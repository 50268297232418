import { Controller } from "@hotwired/stimulus"
import { initDismisses } from "flowbite"

// The element should have following attributes:
// data-controller="notifications--init-dismisses" -> To define the controller, must be on the element

export default class extends Controller {
  // This permits to load the siren search on page load if the siren field is filled
  connect() {
    initDismisses();
    // this.sendRequest();
    setTimeout(() => {
      new Dismiss(this.element).hide();
    }, 5000)
  }
}