import { Controller } from '@hotwired/stimulus'
import List from 'list.js'

export default class extends Controller {
  connect () {
    this.initListJs();
  }

  initListJs() {
    // Si l'élément n'est pas désactivé et si une instance de Choices n'a pas été déjà créée
    if(!this.element.classList.contains('list__init')) {
      this.element.classList.add('list__init');
      const list = new List(this.element.id, {
        page: this.element.getAttribute('data-list-paginate'),
        valueNames: this.element.getAttribute('data-list-options').split(','),
        pagination: this.element.hasAttribute('data-list-paginate') ? [{
          item: "<span class='page-item'><a href='#' class='page'></a></span>"
        }] : null
      });
      if(this.element.hasAttribute('data-list-sort')) {
        list.sort(this.element.getAttribute('data-list-sort'), { order: "asc" });
      }
    }
  }
}