import morphdom from "morphdom"

export function useMorphDom() {
  addEventListener("turbo:before-render", (event) => {
    event.detail.render = (currentElement, newElement) => {
      morphdom(currentElement, newElement, {
        onBeforeElUpdated: function(fromEl, toEl) {
            // spec - https://dom.spec.whatwg.org/#concept-node-equals
            if (fromEl.isEqualNode(toEl)) {
                return false
            }

            return true
        }
      })
    }
  })
}