import { Controller } from "@hotwired/stimulus"
import { on } from "../../javascripts/events";
import { updateOnFormFieldChange } from "../../javascripts/form_content_helpers";

export default class extends Controller {
  connect() {
    let timeout;

    on('#legal-notice-form', 'keyup', 'input.input-classic', event => {
      if(event.target.type === 'date') return;

      clearTimeout(timeout);
      this.hideLegalNoticeOrderButtons();

      timeout = setTimeout(function () {
        updateOnFormFieldChange(event.target, event.target.value);
      }, 500);
    });

    // Changing the value of a date filed by clicking on the calendar
    on('#legal-notice-form', 'change', "input[type='date']", event => {
      clearTimeout(timeout);
      this.hideLegalNoticeOrderButtons();

      timeout = setTimeout(function () {
        updateOnFormFieldChange(event.target, event.target.value);
      }, 500);
    });
  }

  hideLegalNoticeOrderButtons() {
    document.getElementById('legal-notice-order-buttons')?.classList?.add('hidden');
    document.getElementById('legal-notice-validating-input')?.classList?.remove('hidden');
  }
}