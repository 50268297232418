import { Controller } from '@hotwired/stimulus'

// Au clic sur un onglet, désactive tous les onglets et mets celui ci "active"

export default class extends Controller {
  static targets = [ "tabItem" ]

  enableTab(event) {
    this.tabItemTargets.forEach(element => {
      element.classList.remove('tab-active');
      element.classList.add('tab-inactive');
    });
    event.target.classList.add('tab-active');
  }
}