import { sendTurboStreamRequest } from './request_helpers';

export function needRecording() {
  return document.body.hasAttribute('data-record-actions');
}

export function recordAction(event, field, value, id) {
  console.log(event);
  console.log(field);
  console.log(value);
  console.log(id);

  sendTurboStreamRequest(document.getElementById('form_content_actions_url').value, 'POST',
  {
    event_name: event,
    field_name: field,
    value: value,
    field_id: id
  });
}