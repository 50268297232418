import { Controller } from '@hotwired/stimulus'

// Réinitialise les champs du formulaire pour enlever les classes d'erreur sur les labels et les inputs
//

export default class extends Controller {
  connect () {
    this.element.addEventListener('turbo:submit-start', (event) => {
      this.element.querySelectorAll('label').forEach(element => {
        element.classList.remove('label-error');
        element.classList.add('label-classic');
      });
      this.element.querySelectorAll('input').forEach(element => {
        element.classList.remove('input-error');
        element.classList.add('input-classic');
      });
      this.element.querySelectorAll('p.error-message').forEach(element => {
        element.innerHTML = '';
      });

    });
  }
}