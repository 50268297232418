import { Controller } from "@hotwired/stimulus"
import { needRecording, recordAction } from '../../javascripts/recording';

// The element should have following attributes:
// data-controller="views--change-step" -> To define the controller, must be on the element
// data-action="click->views--change-step#changeStep" -> The event to trigger the search
// data-step -> The step to put on next step

export default class extends Controller {
  static targets = [ "link" ]

  changeStep(event) {
    event.preventDefault();

    if(needRecording() === true) {
      let text = this.element.querySelectorAll('.nav-item')[0] ? this.element.querySelectorAll('.nav-item')[0].querySelectorAll('.nav-link')[0].textContent : this.element.textContent;
      recordAction('click', 'step', text.replace(/\n/g, '').trim());
    }

    if(document.body.contains(document.getElementById("next_form_step"))) document.getElementById('next_form_step').value = this.element.getAttribute('data-step');
    document.getElementById('continue_submit').click();
  }
}