import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "counter" ]

  updateCounter(event) {
    const el = event.target;
    this.counterTarget.innerHTML = `${el.value.length}/${el.getAttribute('data-limit')}`;
    if(el.value.length > el.getAttribute('data-limit')) {
      this.counterTarget.classList.add('error-message');
    } else {
      this.counterTarget.classList.remove('error-message');
    }
  }
}